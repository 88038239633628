.WorldClock {
    background-color: white;
    color: #282c34;
    margin: 2px;
    padding: 1px;
    flex-basis: 0;
    flex: 1 1 0px;
    min-width: 10vw;
}

.ClockName {
    font-size: 1.5em;
    font-weight: bold;
    text-align: center;
}

.ClockTime {
    font-size: 1.5em;
    text-align: center;
}

.ClockDate {
    font-size: 1em;
    text-align: center;
}