.LocalToUtcHours {
    background-color: #282c34;
    min-height: 20vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /*font-size: calc(5px + 2vmin);*/
    color: white;
}

.LocalToUtcHour {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid white;
    font-size: 1em;
}

.LocalHour {
    flex-basis: 0;
    flex: 1 1 0px;
    min-width: 6vw;
    text-align: right;
    margin-right: 1vw;
}

.UtcHour {
    flex-basis: 0;
    flex: 1 1 0px;
    min-width: 6vw;
    padding-left: 1vw;
    border-left: 1px solid white;
}