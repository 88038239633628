.UtcClock {
    background-color: #282c34;
    min-height: 30vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 1vmin);
    color: white;
}

.UtcTime {
    font-size: 5em;
}

.UtcDate {
    font-size: 2em;
    text-align: right;
}