.EpochConverters {
    background-color: #282c34;
    min-height: 20vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(5px + 2vmin);
    color: white;
}

.EpochConverter {
    display: flex;
    flex-direction: row;
    margin-top: .5em;
}

.EpochConverterName {
    flex-basis: 0;
    flex: 1 1 0px;
    min-width: 10vw;
    text-align: right;
}

.EpochConverterValue {
    flex-basis: 0;
    flex: 1 1 0px;
    min-width: 10vw;
    padding-left: 3px;
}